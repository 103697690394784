<template>
    <div>

        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo" @handleReset='handleReset'
            @handleCascader="handleCascader" :slot_table_list="['operation','status','is_enable','address']"
            :submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'hotel_id'" @changeStatus="changeStatus">
            <template slot="left_btn">
                <div class="left_btn_box">
                    <div class="recharge_all">
                        <p>当前酒店总数量<span class="all_money">{{all_data.total}}</span></p>
                        <p>营业中酒店数量<span class="all_money">{{all_data.operate}}</span></p>
                    </div>
                    <p>
                        <a-icon type="info-circle" />系统将在每天早上4点-6点之间更新酒店信息
                    </p>
                </div>
            </template>
            <template slot='status' slot-scope="data">
                <span v-if='data.record.status==0'>已关门</span>
                <span v-else-if="data.record.status==1">营业中</span>
                <span v-else-if="data.record.status==2">筹建中</span>
                <span v-else-if="data.record.status==3">暂停营业</span>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a @click="see(data.record)">查看</a>
                </span>
              
            </template>

            <template slot="address" slot-scope="data">
                <span>
                    {{data.record.address?data.record.address:'--'}}
                </span>

            </template>

        </TableList>
    </div>
</template>

<script>
    // @ is an alias to /src
    import EditPop from "@/components/EditPop";
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import SelectUser from "@/components/SelectUser";
    import TransferTree from "@/components/TransferTree";
    import {
        getHotelList,
        orderCount,
        changeHotelStatus,
        getArea
    } from "@/api/evection";
    import {
        getUserList
    } from "@/api/personnel";

    const columns = [{
            title: "酒店ID",
            dataIndex: "hotel_id"
        },
        {
            title: "酒店名称",
            dataIndex: "hotel_name"
        },
        {
            title: "营业状态",
            dataIndex: "status",
            scopedSlots: {
                customRender: "status"
            }
        },
        {
            title: "所在城市",
            dataIndex: "city",
            scopedSlots: {
                customRender: "city"
            }
        },
        // {
        // 	title: "最后同步时间",
        // 	dataIndex: "update_time",

        // },

        {
            title: "操作",
            dataIndex: "operation",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];

    export default {
        name: "Index",
        components: {
            EditPop,
            Form,
            TableList,
            SelectUser,
        },
        data() {
            return {
                area_data2: [],
                getArea_data: [],
                config: this.$config,
                get_table_list: getHotelList,
                submit_preprocessing: {
                    array_to_string: ['department_id', 'place']
                },
                columns,
                selectedRowKeys: [],
                loading: false,
                visible: false,
                form_data_seo: {
                    list: [

                        {
                            type: "text",
                            name: "keyword",
                            title: "酒店名称",
                            placeholder: "酒店名称",
                            options: {}
                        },
                        {
                            type: "cascader",
                            name: "place",
                            title: "所在城市",
                            placeholder: "请选择",
                            options: {
                                // initialValue: "江西省,南昌市"
                            },
                            list: []
                        },
                        {
                            type: 'select',
                            title: '营业状态',
                            name: 'status',
                            options: {},
                            placeholder: "请选择",
                            list: [{
                                    key: '0',
                                    value: "已关门"
                                },
                                {
                                    key: '1',
                                    value: "营业中"
                                },
                                {
                                    key: '2',
                                    value: "筹建中"
                                },
                                {
                                    key: '3',
                                    value: "暂停营业"
                                },
                            ]
                        },

                    ],
                    ...this.$config.form_data_seo
                },
                all_data: {}
            };
        },


        async created() {
            this.get_info({});
            this.get_getArea()
        },

        methods: {
            handleReset(){
                   this.$refs.list.fixed_seo_data.place = '江西省,南昌市';
                this.get_count({
                    place: '江西省,南昌市'
                });
                
            },
            handleSubmit(data) {
                this.get_info(data)
                this.get_count({
                    place: '江西省,南昌市'
                });
                this.get_getArea()
            },
            changeStatus(e) {
                this.get_count(e);
            },
            handleCascader(data) {
                
            },
            get_getArea() {
                getArea().then(res => {
                    this.form_data_seo.list.forEach(item => {
                        if (item.name == "place") {
                            item.list = res.data.list;
                            this.$refs.list.setFieldsValue({
                                place: ['江西省', '南昌市']
                            })
                            this.$refs.list.fixed_seo_data.place = '江西省,南昌市';
                            this.$refs.list.get_list();
                        }
                    });
                })
            },
            handle_area2(data) {
                this.area_data2 = data;
            },
            change_status(data) {
                changeHotelStatus({
                    data: {
                        info: true,
                        hotel_id: data.hotel_id,
                        is_enable: !data.is_enable
                    }
                }).then(res => {
                    this.$refs.list.get_list();
                })
            },
            see(key) {
                this.$router.push("/evection/hotel_details?id=" + key.hotel_id + '&city=' + key.city);
            },
            get_count(e) {
                this.all_data.total = 0;
                this.all_data.operate = 0;
                orderCount({
                    data: {
                        ...e
                    }
                }).then(res => {
                    this.all_data = res.data.data
                })
            },
            get_info(data1) {
                orderCount({
                    data: {
                        keyword: data1.keyword,
                        place: data1.place,
                        status: data1.status
                    }
                }).then(res => {
                    this.all_data = res.data.data

                })
            }
        }
    };
</script>

<style lang="less">
    @import url("../../assets/less/app.less");

    .left_btn_box {
        flex: inherit;

        p {
            margin: 5px 0px;
        }
    }

    .edit_pop {
        .ant-col-19 {
            width: 77%;
        }

        .ant-form-item-label {
            width: 22%;
        }
    }

    .red_color {
        color: red;
    }

    .green_color {
        color: #00CC10;
    }

    .recharge_all {
        font-size: 16px;
        display: flex;
        color: #333;

        .all_money {
            font-size: 20px;
            color: #FF0000;
            margin-left: 10px;
            margin-right: 5px;
            display: inline-block;
        }

        p:nth-child(2),
        p:nth-child(3) {
            margin-left: 40px;
        }
    }
</style>
